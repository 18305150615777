<template>
  <div>
    <v-card
      flat
      class="datatable-card-background datatable-card-border rounded mb-6"
    >
      <v-card-text>
        <v-row>
          <v-col
            cols="12"
            sm="auto"
          >
            <app-branch-select
              v-model="queryParams.branch_id"
              classes="small-input"
              hide-details
              @branch-selected="branchSelected($event)"
            />
          </v-col>

          <v-col
            class="px-1"
            cols="12"
            sm="4"
            md="2"
            lg="2"
            xl="2"
          >
            <v-autocomplete
              v-model="queryParams.inventory_id"
              :label="$t('Inventory')"
              :items="inventoriesList"
              :item-text="item => item.name"
              :item-value="item => item.id"
              outlined
              dense
            />
          </v-col>

          <v-col
            class="px-1"
            cols="12"
            sm="5"
            md="4"
            lg="4"
          >
            <app-item-search
              endpoint="stock/search/"
              :branch-id="queryParams.branch_id"
              :inventory-id="queryParams.inventory_id"
              @selected="handleItemSelect($event)"
            />
          </v-col>

          <v-col>
            <v-btn
              depressed
              small
              class="me-6"
              @click="$_print()"
            >
              <v-icon left>
                mdil-printer
              </v-icon>

              {{ $t('Print') }}
            </v-btn>

            <v-btn
              color="primary"
              depressed
              small
              @click="getData()"
            >
              <v-icon left>
                mdil-magnify
              </v-icon>

              {{ $t('Search') }}
            </v-btn>
          </v-col>
        </v-row>
      </v-card-text>
    </v-card>

    <v-card
      flat
      class="datatable-card-background datatable-card-border rounded"
    >
      <v-card-text>
        <v-simple-table
          id="print-area"
          class="print-table"
          dense
        >
          <template #default>
            <thead>
              <tr class="d-none d-print-table-row">
                <th
                  colspan="6"
                  class="text-center text-subtitle-1 black--text font-weight-bold"
                >
                  {{ $t('Stock Detail') }}
                </th>
              </tr>
              <tr v-if="apiData.stock">
                <th
                  colspan="3"
                  class="text-center text-body-2 black--text font-weight-bold"
                >
                  {{ apiData.stock.item.name }}
                  <span class="ps-2">
                    {{ apiData.stock.item.code }}
                  </span>
                </th>
                <th
                  colspan="3"
                  class="text-center text-body-2 black--text font-weight-bold"
                >
                  {{ $_format_number(apiData.stock.quantity) }} {{ $t('Quantities remains in stock') }}
                </th>
              </tr>
              <tr>
                <th class="text-center">
                  #
                </th>
                <th class="text-center">
                  {{ $t('Date') }}
                </th>
                <th class="text-center">
                  {{ $t('Invoice No.') }}
                </th>
                <th class="text-center">
                  {{ $t('Supplier') }}
                </th>
                <th class="text-center">
                  {{ $t('Quantity') }}
                </th>
                <th class="text-center">
                  {{ $t('Purchase Price') }}
                </th>
              </tr>
            </thead>

            <tbody>
              <tr
                v-for="(data, index) in apiData.data"
                :key="index"
                :class="{
                  'grey': index % 2 == 1,
                  'lighten-3': !$vuetify.theme.dark,
                  'darken-3': $vuetify.theme.dark,
                }"
              >
                <td class="text-center">
                  {{ index + 1 }}
                </td>
                <td class="text-center">
                  {{ $_format_date(data.purchase_date) }}
                </td>
                <td class="text-center">
                  {{ data.purchase_invoice }}
                </td>
                <td class="text-center">
                  {{ data.supplier_name }}
                </td>
                <td class="text-center">
                  {{ $_format_number(data.quantity) }}
                </td>
                <td class="text-center">
                  {{ $_format_number(data.purchase_price) }} $
                </td>
              </tr>
              <tr v-if="apiData.totals">
                <td colspan="4" />
                <td class="text-center font-weight-bold">
                  {{ $_format_number(apiData.totals.quantity) }}
                </td>
                <td />
              </tr>
            </tbody>
          </template>
        </v-simple-table>
      </v-card-text>
    </v-card>
  </div>
</template>

<script>
import axios from 'axios'

export default {
  data() {
    return {
      apiData: {},
      queryParams: {},
      inventoriesList: [],
    }
  },

  mounted() {
    this.$_section_title({ title: 'Stock Detail', icon: 'mdil-chart-bar' })
  },

  methods: {
    branchSelected(data) {
      this.inventoriesList = data.inventories
      this.queryParams.inventory_id = data.inventories[0].id
    },

    handleItemSelect(item) {
      this.queryParams.stock_id = item.stock_id
      this.getData()
    },

    getData() {
      axios.post(`report/stock-detail/`, this.queryParams).then(res => {
        this.apiData = res.data
      })
    },
  },
}
</script>

<style lang="scss" scoped>
</style>